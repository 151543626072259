import React, { useState } from "react"
import { graphql } from "gatsby"
// import Layout from "../components/layout"
// import SEO from "../components/seo"

import { Helmet } from "react-helmet";
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";


import "../../scss/main.scss";
import "../../scss/_blog.scss";

const title = 'Privacy Policy'

export default function Privacy(props) {

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Edyn Care | {title}</title>
                <meta property="og:title" content={`Edyn Care | ${title}`}/>
                <meta property="og:description" content="High quality care in the comfort of your own home." />
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
                <meta property="og:url" content={`https://www.edyn.care/${props.path}`} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="edyn.care" />
                <meta property="twitter:url" content={`https://www.edyn.care/${props.path}`} />
                <meta name="twitter:title" content={`Edyn Care | ${title}`} />
                <meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
            </Helmet>
            <div className="container">
                <Header />
                <main className="blog-post">
                    <div className="blog-post__above-the-fold">
                        <StaticImage
                            src="../../static/assets/marketing-site/images/our-approach-01.png"
                            objectFit='contain'
                            placeholder="blurred"
                            loading="eager"
                        />
                        <div>
                            <h1 className="blog-post__above-the-fold-title">Privacy</h1>
                            <p className="blog-post__above-the-fold-published">8th June 2018</p>
                        </div>
                        <StaticImage
                            src="../../static/assets/marketing-site/images/our-approach-02.png"
                            objectFit='contain'
                            placeholder="blurred"
                            loading="eager"
                        />
                    </div>
                    <section className="blog-post-section">
                        <div className="blog-post__content privacy-policy__content">
                            <p>Welcome to the edyn.care website Privacy Policy. Before you use
                                our website and service, please ensure you read this carefully. If you have any
                                questions relating to this policy please contact our customer service team by
                                email at hello @edyn.care or by phone on 020 3970 9900.
                            </p>
                            <p>
                                Age Abode limited, company number 10713673, (operating as edyn.care) (edyn.care,
                                we, us or our) provides the services offered on the website www.edyn.care.
                            </p>
                            <p>
                                For the purposes of the Data Protection Act 1998 and General Data Protection
                                (Regulation), coming into effect on the 25th of May 2018, we are the data
                                controller.
                            </p>
                            <p>
                                We are committed to safeguarding the privacy of our website visitors. By
                                publishing this policy, we wim to make it easy for our users, customers and the
                                public to understand what data we collect and story, why we do so, how we
                                receive and/or obtain that information, and the rights you have with respect to
                                your data in our possession.
                            </p>
                            <h2>Contact information</h2>
                            <p>
                                Age Abode Limited (Operating as edyn.care) is a company with registered offices
                                at 30 City Road, London, EC1Y 2AB.
                            </p>
                            <p>
                                Email: hello @edyn.care Phone: 020 3970 9900.
                            </p>

                            <h2>What information we collect</h2>
                            <p>
                                In order for us to provide our service it is necessary for us to collect data.
                                This information allows us to identify who you are for the purposes of our
                                business, share data when ask of us, contact you in the ordinary course of
                                business. Without limitation, they type of information we may collect is:
                            </p>
                            <p>
                                For Clients: We may collect and process the following data, which includes
                                personal Data, from you:
                            </p>
                            <p>
                                Personal information. We may collect personal details such as your name, gender,
                                date of birth, profession, nationality, preferences and interests, pets at home,
                                footwear allowed in the home, transportation preferences, smoking, emergency
                                information.
                            </p>
                            <p>
                                Contact information. We may collect information such as your email address,
                                mobile and or/landline telephone number, residential address, house entry
                                instructions
                            </p>
                            <p>
                                Financial information. We may collect financial information related to you about
                                such as credit/card numbers.
                            </p>
                            <p>
                                Statistical information. We may collect information about your online and
                                offline preferences, habits, movements, trends, decisions, association,
                                memberships, finances, purchases and other information for statistical purposes.
                            </p>
                            <p>
                                Device information. We collect device specific information, such as the hardware
                                model, operating system version, advertising identifier, unique application
                                identifiers, browser type , language, wireless network, and mobile network
                                information (including the mobile phone number); any
                            </p>
                            <p>
                                Information you send us. We may collect any personal correspondence that you
                                send us, or that is sent to us bother others (such as background verification
                                companies) about your activities, including activities with our third-party
                                partners.
                            </p>
                            <p>

                                For Carers: We may collect and process the following data, which includes
                                personal Data, from you:
                            </p>
                            <p>
                                Personal information. We may collect personal details such as your name, gender,
                                date of birth, profession, professional history, experience, qualifications,
                                nationality, preferences and interests, pets, transportation preferences,
                                smoking, emergency information, types of services you offer, geographic areas
                                for work, preferred working times, your insurance cover, details of your right
                                to work in the UK;
                            </p>
                            <p>
                                Contact information. We may collect information such as your email address,
                                mobile and or/landline telephone number, residential address;
                            </p>
                            <p>
                                Financial information. We may collect financial information related to you about
                                such as bank details;
                            </p>
                            <p>
                                Statistical information. We may collect information about your online and
                                offline preferences, checkin and checkout information indicating arrival and
                                departure from visits, habits, movements, trends, decisions, association,
                                memberships, finances, purchases and other information for statistical purposes;
                            </p>
                            <p>
                                Device information. We collect device specific information, such as the hardware
                                model, operating system version, advertising identifier, unique application
                                identifiers, browser type , language, wireless network, and mobile network
                                information (including the mobile phone number); and
                            </p>
                            <p>
                                Information you send us. We may collect any personal correspondence that you
                                send us, or that is sent to us other others (such as background verification
                                companies) about your activities, including activities with our third-party
                                partners.
                            </p>
                            <p>
                                Information automatically collected
                            </p>
                            <p>
                                IP address; Geographical location; Web browser type; Operating system; Referral
                                source; Length of visit, page views, website navigation and site you exit to.
                            </p>
                            <p>
                                Before you disclose to us the personal information of another individual, you
                                must obtain that individual’s consent to both the disclosure and the processing
                                of that personal information in accordance with the terms of the Policy.
                            </p>
                            <p>
                                Where we need to collect personal data by law, or under the terms of contract we
                                have with you and you fail to provide that data when requested, we may not be
                                able to perform the contract we have or are trying to enter into with you (for
                                example, to provide you with services). In this case, we may have to cancel the
                                service your have with us but we will notify you if this is the case at the
                                time.
                            </p>
                            <h2>How is you personal data collected?</h2>
                            <p>
                                We use different methods to collect data from and about you including through:
                            </p>
                            <p>
                                Direct interactions. You may give us your personal data by filling in forms or
                                corresponding with us by post, phone, email or otherwise.
                            </p>
                            <p>
                                Automated technologies or interaction. As you interact with our website, we may
                                automatically collect technical data about your equipment, browsing actions and
                                patterns. We collect this personal data by using cookies. Please find our cookie
                                policy below.
                            </p>
                            <p>
                                Third parties or publicly available sources. We may receive personal data about
                                you from various third parties.
                            </p>
                            <h2>We use this information to: </h2>
                            <p>
                                Any or all of the above Data may be required by use from time to time in
                                order to provide you with the best possible service and experience. For the
                                purpose of the Data Protection Act 1998, Age Abode Limited is the “data
                                controller” and is responsible for your personal data. We will only use your
                                personal data when the law allows us to. Most commonly, we may use your personal
                                information for the following reasons: </p>
                            <table
                                style={{
                                    width: "100%"
                                }}>
                                <tr>
                                    <th>
                                        What we use your personal data for
                                    </th>
                                    <th>
                                        Type of data
                                    </th>
                                    <th>Lawful basis for processing including basis of legitimate interest</th>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            width: "33%",
                                            valign: "top"
                                        }}>Provide the customer with care services</td>
                                    <td
                                        style={{
                                            width: "33%",
                                            valign: "top"
                                        }}>Personal, contact, financial, statistical information, device</td>
                                    <td
                                        style={{
                                            width: "33%",
                                            valign: "top"
                                        }}>Perform
                                        the agreement we have with you (or take steps needed at your request prior to
                                        entering in a agreement with you)</td>
                                </tr>
                                <tr>
                                    <td>Maintain customer relationships</td>
                                    <td>Personal, contact</td>
                                    <td>Perform the agreement we have with you (or take steps needed at your request
                                        prior to entering in a agreement with you)</td>
                                </tr>
                                <tr>
                                    <td>Connect a customer with a carer</td>
                                    <td>Personal</td>
                                    <td>Perform the agreement we have with you (or take steps needed at your request
                                        prior to entering in a agreement with you). Where we need to use or share your
                                        sensitive personal data to connect you with a carer, this will be done only with
                                        your permission</td>
                                </tr>
                                <tr>
                                    <td>Communicate our marketing communications, newsletters and promotions</td>
                                    <td>Statistical, device</td>
                                    <td>With your consent</td>
                                </tr>
                                <tr>
                                    <td>Improvements of our products and services
                                    </td>
                                    <td>Personal, contact, financial, statistical information, device</td>
                                    <td>This is within our legitimate business interests to perform</td>
                                </tr>
                                <tr>
                                    <td>Protect against fraud and keep website secure</td>
                                    <td>Financial, statistical information, device</td>
                                    <td>Perform the agreement we have with you (or take steps needed at your request
                                        prior to entering in a agreement with you)</td>
                                </tr>
                                <tr>
                                    <td>Marketing and site metrics performance</td>
                                    <td>Personal, contact, device</td>
                                    <td>With your consent</td>
                                </tr>
                                <tr>
                                    <td>Email marketing</td>
                                    <td>Personal, contact, device</td>
                                    <td>With your consent</td>
                                </tr>
                                <tr>
                                    <td>Payments for services, including invoices and collecting payments</td>
                                    <td>Personal, contact, financial, statistical information, device</td>
                                    <td>Perform the agreement we have with you (or take steps needed at your request
                                        prior to entering in a agreement with you)</td>
                                </tr>
                                <tr>
                                    <td>Accounting purposes</td>
                                    <td>Personal, contact, financial, statistical information, device</td>
                                    <td>Perform the agreement we have with you (or take steps needed at your request
                                        prior to entering in a agreement with you)</td>
                                </tr>
                                <tr>
                                    <td>Communicate with potential and existing customers for marketing purposes</td>
                                    <td>Personal, contact, device</td>
                                    <td>With your consent</td>
                                </tr>
                                <tr>
                                    <td>Verification checks
                                    </td>
                                    <td>Personal, contact</td>
                                    <td>With your consent</td>
                                </tr>
                            </table>

                            <br />
                            <p>
                                You can tell use not to contact you with marketing information regarding our
                                products and services, either at the point such information is collected on the
                                Site (by checking or unchecking (as directed) the relevant box) or, where you do
                                not wish to continue to use your information in this way, by following the
                                unsubscribe instructions on any communications sent to you. You can also
                                exercise the right at any time by contacting us using the Contacting us details
                                at the end this Privacy Policy.
                            </p>
                            <p>
                                Please note that where you opt-out of receiving marketing communications from
                                us, we may still send you service communications about your use of the Site and
                                the related services you have requested from us.
                            </p>

                            <h2>Retention of Data</h2>
                            <p>
                                We will retain your personal Data for a period necessary to fulfill the purposes
                                outlined in this Policy unless a longer retention period is required or
                                permitted by law.
                            </p>
                            <p>
                                To determine the appropriate retention period for personal data, we consider the
                                amount, nature and sensitivity of the personal data, the potential risk of harm
                                from unauthorised use of disclosure of your personal data, the purposes for
                                which we process your personal data and whether we can achieve those purposes
                                through other means, and the applicable legal requirements.
                            </p>
                            <p>
                                The data that we collect from you will be stored in the European Economic Area
                                (EEA), but may be transferred to, and stored at, a destination outside the EEA,
                                with and by third parties.
                            </p>
                            <p>
                                By consenting to our Privacy Policy, you agree to this transfer, storing or
                                processing.
                            </p>
                            <h2>Third party websites and services</h2>
                            <p>
                                We may, from time to time, employ the services of other Third-parties for
                                dealing with certain processes necessary for the operation of the Website. We
                                will only share information with Third-party websites to the extent necessary
                                required by them to perform the services that we request. Any use for other
                                purposes is strictly prohibited.
                            </p>
                            <p>
                                We will not, without your expressed consent, provide your personal information
                                to any Third-parties for the purpose of direct marketing.
                            </p>
                            <p>
                                We require third parties to respect the security of your personal data and to
                                treat it in accordance with the law. We do not allow our third-party service
                                providers to use your personal data for their own purposes and only permit them
                                to process your personal data for specified purposes and in accordance with our
                                instructions.
                            </p>
                            <h2>Verification checks</h2>
                            <p>Age Abode limited may perform the following checks about carers with the
                                following people/bodies: </p>
                            <ul className="alpha">
                                <li>Enhanced DBS (Disclosure and Barring Service) checks with onfido limited; </li>
                                <li>Identity checks with onfido limited;
                                </li>
                                <li>Right to work and documents check (including but not limited to; passport,
                                    driving license and visa) with onfido limited;
                                </li>
                                <li>Adverse history check;
                                </li>
                                <li>Employment history checks; and
                                </li>
                                <li>Checking with two reference checks, both professional and personal.
                                </li>
                            </ul>

                            <h2>Links to other websites</h2>
                            <p>
                                This Website may, from time to time, provide links to other websites. We have no
                                control over such websites and are not responsible for the content of these
                                websites. This privacy policy does not extend to your use of such websites. You
                                are advised to read the privacy policy or statement of other websites prior to
                                using them.
                            </p>
                            <h2>Location Data</h2>
                            <p>
                                We track your travel between appointments using our Platform. This is for the
                                purposes of recording your travel time. You hereby consent to such use of your
                                location data and acknowledge that if you disable this function on your device
                                we are not able to verify your mileage.
                            </p>
                            <h2>How we look after Data</h2>
                            <p>
                                We take a lot of measures to protect your personal information. We seek to use
                                reasonable organisational, technical and administrative measures to protect your
                                information within Age Abode from loss, misuse, unauthorised access or
                                disclosure, alteration and/or destruction. We take reasonable steps to ensure
                                that all data we process is reliable for its intended use, accurate, complete
                                and current to the extent necessary for the purposes for which we use it. We
                                have put in place procedures to deal with any suspected personal data breach and
                                will notify you and applicable regulator of a breach where we are legally
                                required to do so.
                            </p>

                            <p>
                                Examples of such precautions include: Data encryption Firewalls Background
                                checks of all employees
                            </p>
                            <p>
                                If password access is required for certain parts of the Website, you are
                                responsible for keeping this password confidential.
                            </p>

                            <h2>Change of business ownership and control</h2>
                            <p>
                                Age Abode Limited, may, at some point, expand or reduce our business and this
                                may involve the the sale and/or transfer of control of all or part of Age Abode
                                Limited. We have no plans to sell our business. In the unlikely event that we
                                decide to sell any business or assets, we may need to disclose and transfer your
                                personal data, under the terms of this Policy, to the prospective buyer of such
                                business or assets.
                            </p>
                            <h2>Cookies</h2>
                            <p>
                                This Website may places and access certain Cookies on your computer. Age Abode
                                uses Cookies to improve your experience of using the Website and to improve our
                                range of services.
                            </p>
                            <p>
                                All Cookies used by this Website are used in accordance with Current UK and EU
                                Cookie Law.
                            </p>
                            <h2>What are Cookies?</h2>
                            <p>
                                Cookies are alphanumeric identifiers that we transfer to your computer's hard
                                drive through your web browser. They enable us to recognize your web browser. In
                                effect, cookies tell us when you have visited our Website and where you have
                                been, but they do not identify you personally – just the presence of your
                                browser. We use cookies to see how often people visit our Website and what they
                                look at. This helps us to know what parts of the site are popular – what people
                                are interested in – so we can make sure we have more interesting material on the
                                site in future. If you don't want us to be able to do this, your browser's help
                                section should be able to tell you how you can disable or filter cookies and how
                                to arrange for your browser to tell you when you've been sent a new cookies.
                            </p>
                            <p>
                                This Website may place the following Cookies:
                            </p>
                            <p>
                                Necessary cookies - These are cookies that are required for the operation of our
                                website. Without these cookies, services such as, use of logging into secure
                                areas of our website, would not be possible.
                            </p>
                            <p>
                                Analytical - These are cookies that allow us to collect information about the
                                number of visitors and how visitors interact with out site. This information
                                helps us improve the way our website works, it gives us the information to help
                                users find the information they are looking for easily.
                            </p>
                            <p>
                                Performance cookies - We use Google Analytics to analyse the use of this
                                website. Google Analytics generates statistical and other information about
                                website use by means of cookies, which are stored on users' computers. The
                                information generated relating to our website is used to create reports about
                                the use of the website. Google will store this information. Google's privacy
                                policy is available at: http://www.google.com/privacypolicy.html.
                            </p>
                            <p>
                                Functionality cookies - These cookies are used to remember and recognise you
                                when you return to our site. This enables us to provide more personal features
                                and remember your preferences.
                            </p>
                            <p>
                                Targeting cookies - These cookies record your visit to our website to enable us
                                to display advertising relevant to your interests. These cookies use information
                                about your browsing history to group you with other users who have similar
                                interests. We may share this information with third parties for this purpose.
                            </p>

                            <h2>How to delete cookies</h2>
                            <p>You have the right to choose to delete Cookies at any time. To action please
                                contact our customer service team directly. However, please note that by doing
                                so you may lose any information that enables your experience to be more
                                effective. Most web and mobile device browsers automatically accept cookers, but
                                if you prefer, you can change your browser or device settings to prevent that or
                                to notify you each time a cookie is set </p>

                            <h2>Storage of your information</h2>
                            <p>
                                Your personal information which we collect is stored by us on secure services
                                located in the United Kingdom. Such storage is necessary in order to process the
                                information. Personal information may be transferred by us to other offices
                                and/or to the third parties mentioned in the circumstances described above
                                (“Third party websites and services”), which may be situated outside the
                                European Economic Area (EEA) and may be processed by staff operating out the
                                EEA. The countries concerned may not have similar data protection laws to the
                                EEA. Where we transfer your personal information we will take all reasonable
                                steps to ensure that your privacy rights continue to be protected. By using the
                                Site, you agree to this storing, processing and/or transfer.
                            </p>
                            <h2>Consent to collection of data</h2>
                            <p>
                                You may opt to not have us collect your data and communicate with you at certain
                                times. This may prevent us from offering you some or all of our service and may
                                terminate your access to edyn.care, or other services you access with or through
                                us.
                            </p>
                            <p>
                                Opt in. Where relevant, you will have the right to choose to have your
                                information collected and/or receive information from us; or
                            </p>
                            <p>
                                Opt out. You can ask us or third parties to stop sending you marketing messages
                                at any time by contact us at any time. Where you opt out of receiving these
                                marketing messages, this will not apply to personal data provided to us a result
                                of our service.
                            </p>

                            <h2>Your rights</h2>
                            <p>
                                You have a legal right under the Data Protection Act 1998 to a copy of all the
                                personal information about you held by us. On request, we will provide you with
                                a copy of this information. You also have a right to correct any errors in that
                                information. As mentioned above, you have a right to prevent the use of your
                                personal information for direct marketing purposes.
                            </p>
                            <p>
                                You have the right to:
                            </p>
                            <p>
                                Request access to your personal data (commonly known as a "data subject access
                                request"). This enables you to receive a copy of the personal data we hold about
                                you and to check that we are lawfully processing it.
                            </p>
                            <p>
                                Request correction of the personal data that we hold about you. This enables you
                                to have any incomplete or inaccurate data we hold about you corrected, though we
                                may need to verify the accuracy of the new data you provide to us.
                            </p>
                            <p>
                                Request erasure of your personal data. This enables you to ask us to delete or
                                remove personal data where there is no good reason for us continuing to process
                                it. You also have the right to ask us to delete or remove your personal data
                                where you have successfully exercised your right to object to processing (see
                                below), where we may have processed your information unlawfully or where we are
                                required to erase your personal data to comply with local law. Note, however,
                                that we may not always be able to comply with your request of erasure for
                                specific legal reasons which will be notified to you, if applicable, at the time
                                of your request.
                            </p>
                            <p>
                                Object to processing of your personal data where we are relying on a legitimate
                                interest (or those of a third party) and there is something about your
                                particular situation which makes you want to object to processing on this ground
                                as you feel it impacts on your fundamental rights and freedoms. You also have
                                the right to object where we are processing your personal data for direct
                                marketing purposes. In some cases, we may demonstrate that we have compelling
                                legitimate grounds to process your information which override your rights and
                                freedoms.
                            </p>
                            <p>
                                Request restriction of processing of your personal data. This enables you to ask
                                us to suspend the processing of your personal data in the following scenarios:
                                (a) if you want us to establish the data's accuracy; (b) where our use of the
                                data is unlawful but you do not want us to erase it; (c) where you need us to
                                hold the data even if we no longer require it as you need it to establish,
                                exercise or defend legal claims; or (d) you have objected to our use of your
                                data but we need to verify whether we have overriding legitimate grounds to use
                                it.
                            </p>
                            <p>
                                Request the transfer of your personal data to you or to a third party. We will
                                provide to you, or a third party you have chosen, your personal data in a
                                structured, commonly used, machine-readable format. Note that this right only
                                applies to automated information which you initially provided consent for us to
                                use or where we used the information to perform a contract with you.
                            </p>
                            <p>
                                Withdraw consent at any time where we are relying on consent to process your
                                personal data. However, this will not affect the lawfulness of any processing
                                carried out before you withdraw your consent. If you withdraw your consent, we
                                may not be able to provide certain products or services to you. We will advise
                                you if this is the case at the time you withdraw your consent.
                            </p>
                            <h2>Change to this Policy</h2>
                            <p>
                                From time to time, we may update this policy by posting a new version on our
                                website. You should check this page occasionally to ensure you are happy with
                                any changes. If you do not agree with these changes, please do not continue to
                                use our site.
                            </p>
                            <p>
                                We may also notify you of changes to our privacy policy by email.
                            </p>
                            <h2>Contact</h2>
                            <p>
                                We have a appointed a data protection office who is responsible for overseeing
                                questions related to this privacy notice. If you have any questions about this
                                privacy notice, including request to exercise your legal rights, please contact
                                hello@edyn.care.
                            </p>

                            <p>V.1.1 - 8th June 2018</p>
                        </div>
                    </section>
                </main >
                <Footer includeCTA={false}/>
            </div >
        </>
    );
}
